import React from "react";
import { Helmet } from "react-helmet";
import NewsletterSignup from "../components/NewsletterSignup";

export default function info() {
  return (
    <>
      <Helmet>
        <title>Contact Ratio Lighting</title>
        <meta property="og:title" content="Contact Us" key="ogtitle" />
        <meta
          property="og:description"
          content="Our contact details"
          key="ogdesc"
        />
        <meta name="description" content="Our contact details" />
      </Helmet>
      <main id="main">
        {/* <SubHeaderGeneric productName={tempProductName} /> */}
        <div className="grid-content-footer px-medium pt-small fs-mm-xl">
          <article className="grid-child-content pb-large pb-mm-xl">
            <section className="pb-large  max-width-800">
              <p>
                We make lighting tools that allow users to create specific
                lighting effects for harmonious and inviting spatial
                experiences.
              </p>
            </section>
            <section className="pb-large ">
              <p>
                <span className="fw-medium">All Enquiries</span>
                <br />
                <a href="mailto:general@ratio.lighting">
                  general@ratio.lighting
                </a>
              </p>
            </section>
            <section className="pb-large ">
              <p>
                <span className="fw-medium">Ratio HQ</span>{" "}
                <span className="c-fade">(By Appointment Only)</span>
                <br />
                <address>
                  5/195 Lundberg Drive
                  <br />
                  Murwillumbah, NSW 2484
                  <br />
                  Australia
                </address>
              </p>
            </section>

            <hr className="my-medium my-ml-small" />

            <NewsletterSignup />
          </article>
        </div>
      </main>
    </>
  );
}
